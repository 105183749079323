
import Vue, { VueConstructor } from "vue";

import { mapGetters, mapMutations } from "vuex"; // mapState
import { IFormFuncs, ItemDataTable } from "@/types/form-types";

import HarvestForm from "./forms/HarvestForm.vue";
import CrudMixins from "@/mixins/crud";
import Harvest, { ScreenHarvest } from "@/models/harvest";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  name: "AdminHarvest",
  components: {
    HarvestForm,
  },
  mixins: [CrudMixins],
  data() {
    return {
      // dialog da form
      dialog: false,
      // headers da tabela
      harvest_headers: [
        { text: "Nome", value: "name", align: "center" },
        { text: "Período", value: "period", align: "center" },
        { text: "Data de Plantio", value: "begin_date", align: "center" },
        { text: "Data de Colheita", value: "end_date", align: "center" },
        { text: "Data de Criação", value: "created_at", align: "center" },
        { text: "Safra deletada", value: "is_deleted", align: "center" },
        { text: "Data de Delete", value: "deleted_date", align: "center" },
        { text: "Ações", value: "actions", align: "center" },
      ] as ItemDataTable[],
    };
  },
  methods: {
    ...mapMutations(["updateAdminResources"]),
    editHarvest(uharvest: ScreenHarvest): void {
      const form = this.$refs["form"] as IFormFuncs<Harvest>;
      if (form && form.toUpdate) form.toUpdate(new Harvest(uharvest));
    },
    cancel(): void {
      this.$router.push({ path: "/admin/sysmanager" });
    },
  },
  computed: {
    ...mapGetters({
      allHarvests: "getAllHarvests",
      allUnits: "getAllUnits",
    }),
    prepareHarv(): ScreenHarvest[] {
      return this.allHarvests.map((el: Harvest) => {
        return new ScreenHarvest(
          el,
          el.is_current ? "Atual" : el.is_future ? "Futuro" : "Spot"
        );
      });
    },
  },
});
